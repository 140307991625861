.contact-form {
  &.ant-form{
    display: inline-flex;
    align-items: flex-start;
    position: relative;

    @include media('<laptop-small') {
      display: block;
      width: 400px;
    }
    @include media('<old') {
      width: 100%;
    }
  }

  &__item {
    &.ant-form-item {
      margin: 0;
      width: 240px;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }

      @include media('<laptop-small') {
        margin-left: 0;
        margin-bottom: 20px;
        width: 100%;
      }
      &.ant-form-item-has-error {
        .income-input{
          border-color: $red;
          &:focus {
            box-shadow: 0px 4px 0px rgba(181, 227, 255, 0.4);
          }
        }
      }

      .ant-form-item-explain {
        @include font-style($font_avenir_medium, 11px, $red, 1);
        padding-left: 20px;
        margin-top: 10px;
        min-height: initial;
      }
    }

    &--recaptcha {
      width: auto;
      position: absolute;
      right: 0;
      text-align: right;
      top: 50px;


      @include media('<old') {
        text-align: left;
        right: auto;
        left: 0;
        top: auto;
        bottom: 50px;
      }

      &.ant-form-item {
        .ant-form-item-explain {
          padding: 0;
        }
      }
    }
  }

  &__btn {
    @include font-style($size: 14px, $line-height: 20px);
    margin-left: 15px;
    width: 170px;
    padding: 15px 30px;
    border: none;

    @include media('<laptop-small') {
      margin-left: 0;
    }
    @include media('<old') {
      margin-top: 45px;
    }
    @include media('<568px') {
      width: 110px;
    }
  }

}