.tournament-preview {
  padding: 30px;
  border-radius: 15px;
  background: $dark_tile;
  box-shadow: $tile-shadow;

  @include media('<568px') {
    padding: 25px 10px;
  }

  &__image {
    display: block;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 320px;

    @include media('<568px') {
      height: 150px;
    }

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .3s ease;
    }

    &:hover {
      img {
        transform: scale(1.075);
      }
    }

    &--default {
      &:hover {
        img {
          transform: none;
        }
      }
    }
  }

  &__title {
    @include font-style($font_avenir_black, 26px, $white, 1.3);
    text-align: center;
    margin: 0;
    margin-bottom: 10px;

    @include media('<568px') {
      font-size: 21px;
    }
    a {
      color: $white;
      &:hover {
        color: $prime_color_hover;
      }
    }

  }
  &__date {
    @include font-style($font_avenir_black, 21px, $white, 1.3);
    text-align: center;
    margin: 0;
    margin-bottom: 30px;

    @include media('<568px') {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  &__description {
    @include font-style($font_avenir_regular, 14px, $gray, 1.4);
    word-break: break-all;

    @include media('<568px') {
      font-size: 12px;
    }
    ul,ol {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        position: relative;
        padding-left: 20px;

        @include media('<568px') {
          padding-left: 15px;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
        }
      }
    }
    ul {
      li {
        &::before {
          top: 5px;
          width: 8px;
          height: 8px;
          background-color: $prime_color;
          border-radius: 50%;

          @include media('<568px') {
            top: 3px;
          }
        }
      }
    }
    ol{
      counter-reset: itemsCounter;
      li {
        &::before{
          content: counter(itemsCounter) ".";
          counter-increment: itemsCounter;
          font-family: $font_avenir_heavy;
          color: $prime_color;
        }
      }
    }

  }
  &__result {
    .ant-col{
      margin-bottom: 50px;
    }
  }

  .ant-table {
    background: transparent;
    color: $gray;
    font-size: 14px;

    .ant-table-title {
      @include font-style($font_avenir_black, 18px, $white, 1);
    }
    .ant-table-thead > tr > th {
      background: transparent;
      color: $gray;
      border-color: rgba($gray, .5);
      font-family: $font_avenir_black;
    }
    .ant-table-tbody {
      > tr {
        > td {
          border-color:  rgba($gray, .5);
        }

        &:hover {
          >td {
            background: rgba($gray, .1);
          }
        }
      }
    }
  }
}

.prize-container {
  &__place {
    @include font-style($font_avenir_black, 18px, $prime_color, 1);
    @include flex($justify-content: center, $align-items: flex-end);
    padding-bottom: 10px;
    background: url("../../../../assets/images/outer-pages/tournament-winner-icon.svg") no-repeat center / contain;
    width: 40px;
    height: 40px;
  }
}