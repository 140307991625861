.about-item {
  $image-path: '../../../../assets/images/outer-pages/';
  margin-top: 45px;
  position: relative;

  @include media('<old') {
    padding-left: 60px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__title {
    @include font-style($font_avenir_medium, 24px, $prime_color, 1.2);
    margin-bottom: 10px;

    @include media('<old') {
      @include font-style($font_avenir_black, 16px, $white, 1.2);
      margin-bottom: 5px;
    }
  }

  &__text {
    @include font-style($font_avenir_medium, 18px, $white, 24px);

    @include media('<old') {
      font-size: 14px;
    }
  }
  &__icon {
    display: none;

    @include media('<old') {
      position: absolute;
      left: 0;
      top: 2px;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 50px;
      height: 12px;

      &--0 {
        background-image: url($image-path + 'about-icon-1.svg');
      }
      &--1 {
        background-image: url($image-path + 'about-icon-2.svg');
      }
      &--2 {
        background-image: url($image-path + 'about-icon-2.svg');
      }
      &--3 {
        background-image: url($image-path + 'about-icon-3.svg');
      }
      &--4 {
        background-image: url($image-path + 'about-icon-4.svg');
      }
    }
  }
}