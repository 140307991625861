.contacts-nav {
  @include absolute-center(false, true);
  right: 30px;
  position: fixed;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 999;

  @include media('<laptop') {
    right: 5px;
  }
  @include media('<old') {
    @include flex;
    transform: none;
    top: auto;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  &__item {
    margin-bottom: 20px;

    @include media('<old') {
      margin-bottom: 0;
      flex: 1;
      border-left: 1px solid $dark_tile;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    @include flex($type: center);
    border-radius: 50%;
    background: #303030;
    box-shadow: 0 0 5px rgba(35, 41, 57, 0.5);
    width: 60px;
    height: 60px;
    transition: background-color .3s ease, transform .3s ease, box-shadow .3s ease;

    @include media('<laptop') {
      width: 45px;
      height: 45px;

      svg {
        width: 20px;
      }
    }

    @include media('<old') {
      width: 100%;
      height: 40px;
      border-radius: 0;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    path {
      transition: all .3s ease;
    }

    &:hover {
      background-color: $prime_color_hover;
      transform: scale(1.125);
      box-shadow: 0 0 20px -3px $prime_color_hover;

      @include media('<old') {
        transform: none;
      }

      path {
        fill: #303030;
      }
    }
  }
}