@import "defaults";

@include large-mobile {
  .default__bg-layer {
    .header {
      height: unset;
      padding-top: 20px;
      margin: 0 40px 67px;
      width: calc(100% - 80px);
    }

    .footer {
    }
  }
}

@include tablet {
  .default__bg-layer {
    background: url("../../images/landings/commissions_tab/header-bg-large-blue.svg")
        no-repeat top -30px right,
      url("../../images/landings/faq/top-left-circle.svg") no-repeat top left,
      url("../../images/landings/commissions_tab/header-bg-large-gray.svg")
        no-repeat top -30px right,
      url("../../images/landings/faq/left-vector-orange.svg") no-repeat left top
        464px,
      url("../../images/landings/faq/left-vector.svg") no-repeat left top 520px,
      url("../../images/landings/faq/right-vector-gray.svg") no-repeat right top
        710px,
      url("../../images/landings/footer-blue-bg--desktop.png") no-repeat bottom -10px
        center,
      url("../../images/landings/footer-gray-bg--desktop.png") no-repeat bottom -20px
        center,
      url("../../images/landings/faq/bottom-circle-orange.svg") no-repeat bottom
        left 344px;

    .footer {
      padding-bottom: 10px;
    }
  }
}

@include desktop {
  .default__bg-layer {
    max-width: 1440px;
    margin: 0 auto;

    .header {
      padding-top: 55px;
      margin: 0 150px 82px;
      width: calc(100% - 300px);
    }

    .footer {
    }
  }
}
