.beverages-banner {
  $image-path: '../../../../assets/images/outer-pages/';
  border-radius: $border-radius;
  background: $dark_tile;
  min-height: 360px;
  padding-top: 70px;
  padding-left: 60px;
  position: relative;
  margin-bottom: 15px;

  @include media('<old') {
    padding: 25px 20px;
    min-height: 300px;
  }

  @include media('<568px') {

    &::before{
      content: '';
      background: url($image-path + "beverage-banner-shadow.svg") no-repeat center / cover;
      height: 120px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 2;
    }
  }

  &__title {
    @include font-style($font_avenir_black, 36px, #fff, 1.3);

    @include media('<old') {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  &__txt {
    @include font-style($font_avenir_medium, 18px, $gray, 1.4);
    padding-right: 20px;
    margin: 0;
    max-width: 270px;

    @include media('<old') {
      font-size: 16px;
      padding-right: 0;
      max-width: 270px;
    }
  }
  &__info {
    width: 600px;
    max-width: 100%;
    position: relative;
    z-index: 5;
  }
  &__btn {
    margin-top: 20px;

  }
  &__img {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 1;
  }

  &--0 {
    .beverages-banner {
      &__img {
        width: 465px;
        height: 361px;
        background-image: url($image-path + "beverage-banner-1.png");

        @include media('<laptop-small') {
          width: 400px;
          height: 300px;
          right: -10px;
        }
        @include media('<568px') {
          width: 282px;
          height: 167px;
          background-image: url($image-path + "beverage-banner-1-xs.png");
          right: 10px;
        }
      }
    }
  }
}