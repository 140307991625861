.commission-item {
  @include flex($direction: column, $justify-content: center);
  background-color: $dark_tile;
  box-shadow: $tile-shadow;
  padding: 25px;
  padding-top: 50px;
  border-radius: 15px;
  text-align: center;
  height: 350px;
  border: 1px solid rgba($prime_color, .2);

  @include media('<568px') {
    padding: 30px 20px;
    height: auto;
  }

  &__title {
    @include font-style($font_avenir_black, 26px, $white, 1);
    margin: 0;
    margin-bottom: 20px;

    @include media('<568px') {
      font-size: 18px;
    }
  }
  &__text {
    @include font-style($font_avenir_medium, 18px, $gray, 1.4);
    padding: 0 40px;
    margin-bottom: 20px;

    @include media('<568px') {
      padding: 0;
      font-size: 14px;
    }
  }
  &__btn {
    margin-top: auto;

    @include media('<old') {
      display: block;
      margin: 0 auto;
      margin-top: auto;
      width: 180px;
    }
  }
}