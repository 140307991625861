.faq-nav {
  @include flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 100px;

  @include media('<laptop-small') {
    margin-bottom: 50px;
  }

  @include media('<old') {
    margin-bottom: 25px;
  }

  &__item {
    margin-left: 25px;

    @include media('<old') {
      flex: 1;
      margin-left: 10px;
    }


    &:first-child {
      margin-left: 0;
    }

    &--active {
      .faq-nav {
        &__btn {
          color: $prime_color;

          @include media('<old') {
            color: $white;
          }

          &::before {
            opacity: 1;
            transform: translateX(-50%) scaleX(1);

            @include media('<old') {
              background-color: $prime_color;
            }
          }
        }
      }
    }
  }

  &__btn {
    @include font-style($font_avenir_black, 24px, #828282, 1);
    border: none;
    cursor: pointer;
    background: none;
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    transition: color .3s ease;
    padding: 0;
    padding-bottom: 10px;


    @include media('<old') {
      @include font-style($font_avenir_medium, 18px, #B1B1B1, 1);
      padding-bottom: 13px;
    }

    &:hover {
      color: $prime_color;

      @include media('<old') {
        color: $white;
      }
    }

    &::before {
      content: '';
      @include absolute-center(true, false);
      bottom: 0;
      width: 85%;
      height: 3px;
      opacity: 0;
      background-color: $prime_color;
      transition: opacity .3s ease, transform .3s ease;
      transform: translateX(-50%) scaleX(0);

      @include media('<old') {
        width: 100%;
        background-color: #141415;
        transition: background-color .3s ease;
        opacity: 1;
        transform: translateX(-50%) scaleX(1);

      }
    }

    &[disabled] {
      opacity: .3;
      cursor: default;
      pointer-events: none;
    }
  }
}