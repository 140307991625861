.benefit-item {
  $image-path: '../../../../assets/images/outer-pages/';

  @include flex($align-items: center);
  background-color: $dark_tile;
  border-radius: 15px;
  min-height: 140px;
  padding-left: 60px;
  box-shadow: $tile-shadow;

  @include media('<laptop-small') {
    padding-left: 15px;
  }
  @include media('<old') {
    padding-left: 0;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__title {
    @include font-style($font_avenir_medium, 18px, #fff);
    max-width: 185px;
    transition: color .35s ease-out;
  }

  &__image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
    margin-right: 30px;
    transition: transform .2s ease;


    @include media('<laptop-small') {
      margin-right: 15px;
    }
  }


  &--0 {
    .benefit-item {
      &__image {
        width: 87px;
        height: 86px;
        background-image: url($image-path + 'benefit-1.svg');

        @include media('<375px') {
          width: 60px;
        }
      }
    }
  }

  &--1 {
    .benefit-item {
      &__image {
        width: 82px;
        height: 82px;
        background-image: url($image-path + 'benefit-2.svg');
        @include media('<375px') {
          width: 60px;
        }
      }
    }
  }

  &--2 {
    .benefit-item {
      &__image {
        width: 82px;
        height: 63px;
        background-image: url($image-path + 'benefit-3.svg');
        @include media('<375px') {
          width: 60px;
        }
      }
      &__title {
        max-width: 190px;
      }
    }
  }

  &:hover {
    .benefit-item{
      &__title {
        color: $prime_color;
      }
      &__image {
        transform: scale(1.15);
      }
    }
  }
}