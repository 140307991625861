@import "vars";
@import "../assets/styles/main";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";

@import "modules/affiliates-programs";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
