.presentation-nav {
  padding: 0;
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  @include media("<768px") {
    align-items: flex-start;
    margin: 30px 0;
  }

  &__item {
    @include media("<768px") {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
    }
  }
  &__item + &__item{
    margin-left: 40px;

    @include media("<768px") {
      margin-left: 10px;
    }
  }
}

.presentation-nav-item {
  padding: 10px 0;
  border: none;
  cursor: pointer;
  position: relative;
  padding-left: 45px;
  font-family: $font_avenir_black;
  color: #B1B1B1;
  background: none;
  font-size: 24px;
  transition: all .3s ease;
  display: flex;
  align-items: center;

  @include media("<1200px") {
    font-size: 18px;
  }
  @include media("<992px") {
    font-size: 14px;
  }
  @include media("<768px") {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-top: 0;
    font-size: 12px;
  }
  @include pseudo-element(before) {
    width: calc(80% - 45px);
    height: 3px;
    background: $prime_color;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    bottom: 0;
    margin-left: 22.5px;
    transition: transform .3s ease;

    @include media("<768px") {
      margin-left: 0;
    }
  }
  &__icon {
    width: 32px;
    height: 32px;
    @include absolute-center(false, true);
    left: 0;

    @include media("<768px") {
      position: relative;
      transform: none;
      margin-bottom: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &--active {
    color: $prime_color;

    &::before {
      transform: translateX(-50%) scaleX(1);
    }
  }
}