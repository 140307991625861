@import "defaults";

.main-wrap {
  position: relative;
  padding: 10px 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: url("../../images/landings/wrapper_bg/bg-elem-elipse_3.14.png") no-repeat right calc((100vw + 114px)/1.5) top 80px,
              url("../../images/landings/wrapper_bg/bg-elem-elipse_2.17.png") no-repeat right 136px top 60px,
              url("../../images/landings/wrapper_bg/bg-elem-ellipse_3.14-1.png") no-repeat left calc((100vw - 20px)/1.3) top 49px,
              url("../../images/landings/wrapper_bg/bg-elem-rectangle_4.27.png") no-repeat left calc((100vw + 80px)/1.5) top 283px,
              url("../../images/landings/wrapper_bg/bg-elem-rectangle_4.27-1.png") no-repeat left calc((100vw + 30px)/1.2) top 122px,
              url("../../images/landings/wrapper_bg/bg-elem-ellipse_3.15.png") no-repeat right calc((100vw + 101px)/1.5) top 285px,
              url("../../images/landings/wrapper_bg/bg_elem_1.png") no-repeat right calc((100vw - 315px)/2) top 44px,
              url("../../images/landings/wrapper_bg/bg_elem_2.png") no-repeat right top,
              url("../../images/landings/wrapper_bg/bg-elem-blob-middle.png") no-repeat left 0 top 425px,
              url("../../images/landings/wrapper_bg/bg-elem-ellipse_middle.png") no-repeat left 7px top 467px,
              url("../../images/landings/wrapper_bg/bg-elem_how-it-works_section_desktop.png") no-repeat center top 930px;
}

section {
  margin: 0 auto 101px;
}

.header {
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 43px;
  margin: 0 20px 260px;
  width: calc(100% - 40px);
  font-family: $mainSansSerif, sans-serif;

  &__logo{
    &-cont {
    }
  }

  &__mobile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 66px;
    font-weight: normal;
    color: $defaultWhite;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }

  &__desktop-menu {
    display: none;
  }
}

.welcome {
  max-width: 455px;
  padding: 0 20px;
  font-family: $mainSansSerif, sans-serif;

  p {
    margin: 7px 0 30px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .btn__scroll--down {
    display: none;
  }
}

.slider {
  max-width: 455px;
  margin: 0 auto;

  .slick-dots {
    bottom: -1px;

    li {
      width: 8px;
      margin: 0 1.5px;

      button{
        width: 100%;
        padding: 0;

        &:before {
          top: 6px;
          width: 0;
          height: 0;
          opacity: 1;
          content: '';
          border-radius: 50%;
          border: solid $dotBlue 4px;
        }
      }
    }

    li.slick-active{
      width: 31px;
      margin: 1.5px;

      button{
        width: 100%;
        padding: 0;

        &:before {
          top: 6px;
          width: 23px;
          height: 0;
          opacity: 1;
          content: '';
          border-radius: 5px;
          border: solid $mainOrange 4px;
        }
      }
    }
  }

  .slick-arrow {
    width: 66px;
    height: 66px;
    background: $defaultWhite;
    box-shadow: 0 15px 50px $slideBoxShadow;
    border-radius: 50%;
    z-index: 1;
    top: 139px;

    &:hover, :focus {
      color: black;
      outline: none;
      background: $defaultWhite;
    }
  }

  .slick-prev {
    left: 3px;

    &:before {
      opacity: 1;
      content: url("../../images/landings/prev-btn.svg");
    }

    &:focus {
      background: $defaultWhite;
    }
  }

  .slick-prev.slick-disabled {
    &:before {
      opacity: unset;
      content: url("../../images/landings/prev-btn-disabled.svg");
    }
  }

  .slick-next {
    right: 3px;

    &::before {
      opacity: 1;
      content: url("../../images/landings/next-btn.svg");
    }

    &:focus {
      background: $defaultWhite;
    }
  }

  .slick-next.slick-disabled {
    &:before {
      opacity: unset;
      content: url("../../images/landings/next-btn-disabled.svg");
    }
  }
}

.slider__item {
  min-width: 310px;
  position: relative;
  height: 328px;
  background: transparent;
}

.slider__item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 43px 11px 35px;
  max-width: 280px;
  height: 278px;
  border-radius: 0 30px 0 30px;
  background-color: $defaultWhite;
  box-shadow: 0 15px 40px $slideBoxShadow;
  font-family: foco, sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  color: $titleBlack;

  &__img-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 105px;
  }
}

.our-benefits {
  padding-top: 5px;
  max-width: 455px;
  margin: 0 auto;

  .slider {
    margin: 23px auto 20px;

    &__item {
      &__img-cont {
        display: flex;
        align-items: center;
        height: 120px;
      }
    }
  }
}

.how-it-works {
  counter-reset: item-num;
  padding-top: 101px;

  .slider {
    margin: 16px auto 0;

    & .slick-arrow{
      top: 151px;
    }

    &__item {
      min-height: 325px;
    }

    &__item-content{
      counter-increment: item-num;
      margin-top: 34px;
      padding-top: 61px;
      position: relative;
      width: 280px;
      height: 245px;
      border-radius: 0 30px 0 30px;

      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border: solid #eff5ff 7px;
        color: $defaultWhite;
        top: -33.5px;
        left: calc(100%/2 - 37px);
        content: counter(item-num);
        width: 60px;
        height: 60px;
        background: linear-gradient(0deg, #F47D20 12.5%, #F7C443 100%);
        border-radius: 50%;
      }
    }
  }
}

.commissions {
  .slider {

    & .slick-arrow{
      top: 288px;
    }

    &__item {
      height: 557px;
      padding: 140px 20px 0;
    }

    &__item-content {
      position: relative;
      padding: 105px 44px 42px;
      max-width: 380px;
      height: 365px;

      &__img-plaque {
        position: absolute;
        top: -84.5px;
        left: calc(100%/2 - 84.5px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 169px;
        height: 169px;
        background-color: $defaultWhite;
        border-radius: 50%;
        box-shadow: 0 15px 50px rgba(0, 102, 255, 0.15);
      }

      span {
        font-family: $mainSansSerif;
        font-size: 18px;
        line-height: 21px;
        font-weight: normal;
      }
    }
  }
}

.portfolio {

  .slider {
    margin: 0 auto;

    & .slick-arrow{
      height: 45px;
      width: 45px;
      top: 129px;
    }

    & .slick-dots {
      bottom: -15px;
    }

    &__wrap {
      padding: 0 20px;
    }

    &__portfolio-item {
      display: flex;
      flex-direction: column;
      max-width: 380px;
      width: 100%;
      margin: 39px auto;

      &__logo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        margin-bottom: 25px;
        background-color: $defaultWhite;
        border-radius: 30px 30px 0 0;
        box-shadow: 0 15px 40px $slideBoxShadow;

        &:after {
          position: absolute;
          bottom: -12px;
          height: 0;
          width: 0;
          content: '';
          border-top: 12px solid $defaultWhite;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        h3 {
          align-self: flex-start;
          text-align: left;
        }

        span {
          margin-top: 9px;
          font-family: $mainSansSerif;
          font-size: 14px;
          line-height: 17px;
          font-weight: normal;
        }

        button {
          margin: 31px 0;

          a {
            padding: 0;
          }
        }
      }
    }
  }
}

.contact {
  padding: 0 20px;
  margin: 0 0 8px 0;
  background: url("../../images/landings/pixel-world-bg--desktop.png") no-repeat center;
  background-size: cover;

  & > span {
    display: block;
    max-width: 300px;
    margin: 11px auto 0;
    text-align: center;
    font-family: $mainSansSerif;
  }

  .err {
    padding: 12px 22px;
    border: solid 2px $mainOrange;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $mainSansSerif;
    max-width: 445px;
    margin: 0 auto;

    #message {
      height: 171px;
    }
  }

  .form-error {
    position: relative;
    display: inline-block;
    left: 11px;
    margin: 5px 0 0;
    height: 24px;
    padding: 6px 13px;
    background-color: $mainOrange;
    border-radius: 15px;
    color: $defaultWhite;
    font-family: $mainSansSerif;
    font-size: 12px;
    text-align: left;
    line-height: 14px;

    &:before {
      position: absolute;
      top: -4px;
      left: 14px;
      content: '';
      border-bottom: 5px solid $mainOrange;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .btn__default {
    margin-top: 20px;
  }

  .btn__scroll--up {
    display: none;
  }

  .recaptcha {
    > div {
      > div {
        margin: 20px auto 0;
      }
    }
  }
}

.footer {
  min-height: 290px;
  margin: 0;
  padding: 100px 20px 20px;
  font-family: $mainSansSerif;
  color: $defaultWhite;
  text-align: center;
  background: url("../../images/landings/footer-blue-bg--desktop.png") no-repeat bottom center;
  background-size: auto 500px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__info {
    margin-top: 24px;
    order: 2;
    font-size: 12px;
    line-height: 14px;

    &::selection {
      background: $mainOrange;
    }
  }

  &__menu {
    padding: 0;
    list-style: none;
    &-item {
      a {
        position: relative;
        color: $titleBlack;
        font-size: 11px;
        line-height: 25px;
        text-transform: uppercase;
        text-decoration: none;

        &::after {
          display: inline-block;
          position: absolute;
          bottom: -2px;
          left: 0;
          content: '';
          width: 100%;
          height: 0;
          border-bottom: solid 2px;
          border-image: $underlineGradient 1;
          border-radius: 6px;
          opacity: 0;
          transition: linear 0.2s;
        }
      }

      &:hover {
        a::after {
          opacity: 1;
          transition: linear 0.2s;
        }
      }
    }
  }
}
