.presentation-container {
  .presentation-video {
    max-width: 80%;
    height: 100%;
    margin: 0 auto;

    @include media('<568px') {
      max-width: 100%;
    }

    video {
      width: 100%;
      border-radius: 10px;
    }

  }
}