.how-it-works-slider-item {
  width: 310px;
  flex-shrink: 0;
  margin-right: 5px;

  &__info {
    border-top: 1px dashed rgba(0, 0, 0, 0.15);
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 30px;
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }
    &::before{
      height: 24px;
      width: 1px;
      border-left: 1px dashed rgba(0, 0, 0, 0.15);
    }

    &::after {
      width: 10px;
      height: 10px;
      background: #98c5a6;
      left: -4.5px;
      top: 23px;
      border-radius: 50%;
    }
  }
  &__icon {
    @include flex($justify-content: center);
    padding-bottom: 55px;
    position: relative;

    &::before {
      content: '';
      @include absolute-center(true, false);
      bottom: 5px;
      width: 300px;
      height: 55px;
      background: url("../../../../assets/images/outer-pages/how-it-works-icon-shadow.svg") no-repeat center / contain;

    }
  }

  &__title{
    @include font-style($font_avenir_heavy, 16px, #303030, 1.3);
    margin-bottom: 5px;
  }

  &__text {
    @include font-style($font_avenir_regular, 14px, rgba(48, 48, 48, 0.5), 1.4)
  }
}