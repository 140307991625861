.ant-input.income-input{
  @include font-style($font_avenir_medium, 12px, $dark, 1);
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 0px rgba(181, 227, 255, 0.4);
  border-radius: 17px;
  border: 1px solid transparent;
  display: block;
  width: 100%;
  outline: none !important;
  @include placeholder-color(rgba(48, 48, 48, 0.4));
  @include placeholder-font-family($font_avenir_medium);
  transition: border-color .2s ease, box-shadow .2s ease;

  &:focus,
  &:hover{
    border-color: transparent;
    box-shadow: 0px 4px 0px rgba(181, 227, 255, 0.4);
  }
}