.burger-menu {
  $image-path: '../../../../assets/images/outer-pages/';
  width: 24px;
  height: 16px;
  background: url($image-path + "burger-menu.svg") no-repeat center/ contain;
  padding: 0;
  border: none;
  cursor: pointer;
  margin-right: 30px;
  flex: none;
  transition: background .2s ease;

  @include media('<568px') {
    margin-right: 10px;
  }

  &--opened {
    background-image: url($image-path + 'burger-menu-close.svg');
  }
}