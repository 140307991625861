.auth-input-box {
  position: relative;

  &__icon {
    @include flex($type: center);
    @include absolute-center(false, true);
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg{
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
    path {
      transition: all .2s ease;
    }

    &--user {
      height: 18px;
    }
  }

  .ant-input ,
  .ant-input-affix-wrapper > input.ant-input{
    @include font-style($font_avenir_regular, 14px, $white, 1);
    height: 40px;
    padding-left: 40px;
    padding-right: 40px;
    background: $outer_dark_color;
    border: 1px solid transparent;
    border-radius: 8px;
    display: block;
    width: 100%;

    @include placeholder-color($gray);
    @include placeholder-font-family($font_avenir_regular);

    &:focus,
    &:hover{
      border-color: transparent;
      box-shadow: none;
    }
  }
  .ant-input-affix-wrapper {
    border-color: transparent !important;
    box-shadow: none !important;
    background: none !important;
    border-radius: 0 !important;
    
    &::before {
      display: none;
    }
    &:hover {
      box-shadow: none;
    }
  }
  .ant-input-password {
    .ant-input-suffix {
      @include absolute-center(false, true);
      right: 10px;

      svg {
        width: 20px;
        height: 20px;
      }

      path {
        fill: #828282;
        transition: all .2s ease;
      }

      .anticon-eye {
        path {
          fill: $prime_color;
        }
      }
    }
  }

}