$primaryMain: #f17d32;
$primaryActiveColor: #e67032;
$secondaryMain: #0066ff;
$tertiaryMain: #e5f0ff;
$tertiaryActiveColor: #0066ff;
$defaultMain: #e9f7f0;
$successStatus: #27ae60;
$errorStatus: #e42320;
$defaultActiveMain: #27ae60;
$defaultActiveMainTransparent: rgba(39, 174, 96, 0.1);
$stroke: #e0e8f5;
$popup_text: #bfcade;
$alwaysWhite: #fff;
$alwaysBlack: #03060e;
$alwaysGrey: #bbc4cd;
$darkGreyColor: #93969d;
$greyMain: #8295a6;
$error_color: #ed5566;
$main_bg: #f4f7fc;
$white_bg: #fff;
$lightsteelblue_bg: #bfcade;
$dart_blue: #21252f;
$primaryBtnBg: $primaryMain;
$primaryBtnBgHover: #ff9d5e;
$primaryBtnBgPressed: #c65f1c;
$primaryBtnBgDisabled: #fbd8c2;
$primaryBtnShadow: rgba(244, 125, 32, 0.3);
$primaryOpacity10: rgba(244, 125, 50, 0.1);
$defaultActiveOpacity10: rgba(39, 174, 96, 0.1);
$secondaryBtnBg: #fef2ea;
$secondaryBtnColor: $white_bg;
$secondaryBtnBgHover: #dbe6f5;
$secondaryBtnShadow: rgba(229, 240, 255, 0.65);
$tertiaryBtnBg: $tertiaryMain;
$tertiaryBtnColor: $tertiaryActiveColor;
$tertiaryBtnDisabled: $main_bg;
$defaultBtnBg: $defaultMain;
$defaultBtnColor: $defaultActiveMain;
$defaultBtnBgHover: #e0eee7;
$defaultBtnShadow: rgb(213, 234, 224);
$sidebarIconColor: $primaryMain;
$currencyColor: #6fcf97;
$fieldsActiveBorderColor: $lightsteelblue_bg;
$fieldsActiveBoxShadow: rgba(223, 232, 245, 0.5);
$switchDisabledBtn: $alwaysGrey;
$switchBgColor: $darkGreyColor;
$promoCardBoxShadow: 0px 5px 20px rgba(204, 215, 234, 0.3);
$promoCardActiveBoxShadow: 0px 10px 30px rgba(204, 215, 234, 0.7);
$radioBtnActive: #1890ff;
