@import "defaults";

.commissions__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 10px 0 0;
  background: url("../../images/landings/commissions_tab/header-blob.png") no-repeat right top,
              url("../../images/landings/commissions_tab/Vector 2.14.png") no-repeat left 0 top 307px,
              url("../../images/landings/commissions_tab/Vector 2.15.png") no-repeat left 0 top 434px,
              url("../../images/landings/commissions_tab/Vector 2.16.svg") no-repeat right 0 top 866.82px,
              url("../../images/landings/commissions_tab/Ellipse 4.1.png") no-repeat left 0 top 522px,
              url("../../images/landings/commissions_tab/header-gray-blob.png") no-repeat right top;

  .header {
    margin-bottom: 67px;
  }

  .commissions {
    width: 100%;
    max-width: 455px;
    margin-bottom: 0;

    h2 {
      padding-left: 20px;
      font-size: 35px;
      line-height: 41px;
      text-align: left;
      letter-spacing: unset;
    }

    h3 {
      font-weight: bold;
    }

    .slider {
      position: relative;
      &__item {
        width: unset;
        min-width: 280px;
        padding-top: 130px;
      }
    }
  }

  .footer {
    min-height: unset;
    padding-top: 25px;
  }
}
