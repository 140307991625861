@mixin font-face($font_name) {
  $file_path: '../fonts';
  @font-face {
    font-family: quote($font_name);
    src: url('../../fonts/' + $font_name + '.woff') format('woff'),
    url('../../fonts/' + $font_name + '.woff2') format('woff2');
  }
}
@include font-face('Avenir-Regular');
@include font-face('Avenir-Medium');
@include font-face('Avenir-Heavy');
@include font-face('Avenir-Black');

$font_avenir_regular: 'Avenir-Regular', sans-serif;
$font_avenir_medium: 'Avenir-Medium', sans-serif;
$font_avenir_heavy: 'Avenir-Heavy', sans-serif;
$font_avenir_black: 'Avenir-Black', sans-serif;


