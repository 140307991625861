.presentation-section {
  padding-bottom: 45px;

  &__text {
    margin-bottom: 25px;
  }

  &__info {
    text-align: center;
    color: #B1B1B1;
    width: 350px;
    margin: 0 auto;
    margin-top: -15px;

    @include media("<768px") {
      width: 100%;
      max-width: 375px;
    }

    .income-button {
      min-width: 275px;

      @include media("<768px") {
        min-width: initial;
        width: 100%;
      }
    }
  }
}