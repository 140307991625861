.partners-page {

  &__text {
    max-width: 540px;
    color: #B1B1B1;
  }
}
.partners-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-top: 30px;

  &__item {
    width: 25%;
    padding: 0 10px;
    padding-bottom: 20px;

    @include media("<old") {
      width: calc(100% / 3);
    }
    @include media("<768px") {
      width: 50%;
    }
    @include media("<568px") {
      width: 100%;
    }
  }
}

.partner-list-item {
  background: #373737;
  border-radius: 14px;
  height: 340px;
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }

  &__img {
    max-width: 165px;
    height: 85px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
  &__title {
    font-family: $font_avenir_heavy;
    font-size: 24px;
    line-height: 113.6%;
    color: #fff;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
    color: #D1D5DB;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    height: 48px;
  }

  .income-button {
    margin-top: auto;
  }

  &--disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
    position: relative;
    user-select: none;
    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
    }
  }
}