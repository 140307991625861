.system-table {
  overflow-x: auto;
  margin: 0 -24px;
  position: relative;
}

.sidebar-logo {
  display: block;
  text-align: center;
}
.inner-form-card {

  &__top {
    display: flex;
    padding-bottom: 20px;
  }
  &__controls {
    display: flex;
    padding-top: 25px;
    > * {
      + * {
        margin-left: 15px;
      }
    }
  }
}

.geo-chart-map {
  height: 700px;
  max-height: 60vh;


}
.geo-chart-map-wrap {
  margin-top: 20px;

  .ant-card-body {
    padding: 0;
  }
}
.geo-detail-modal {


  .ant-table-wrapper {
    overflow: auto;
    margin-top: 20px;
    white-space: nowrap;
  }
}

.moved-brand {
  text-align: center;
  max-width: 700px;
  border-radius: 10px;
  padding:20px 12px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 16px;
  background: #fff;

  a {
    color: $primaryActiveColor;
    font-size: 18px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &__info {
    margin-top: 15px;
  }
}
.dashboard-totals {
  background: #e0e8f5;
  border: none;
  border-radius: 6px;
  overflow: hidden;

  .ant-card-body {
    padding: 0;
  }
  .ant-row {
    > .ant-col {
      max-width: initial;
      flex-grow: 1;
    }
  }


  &--gamers {
    .ant-list-item {
      height: 158px !important;
    }
  }
}
.affiliate-geo-detail-modal {
  .ant-table-wrapper {
    overflow-x: auto;
  }
}
.country-item {
  display: flex;
  align-items: center;
  img {
    width: 32px;
    margin-right: 5px;
    flex: none;
  }
  &__text {
    flex: none;
    white-space: nowrap;
  }
}