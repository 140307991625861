@import "defaults";

.testimonials {
  align-content: center;
  max-width: 1200px;
  margin: 40px auto 100px auto;

  .ant-card-head {
    background-color: $stroke;
  }

  .testimonial-card {
    margin: 15px 20px;
  }
}
