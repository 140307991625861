@mixin pseudo-element($pseudo-element: after, $content: '', $position: absolute) {
  @if ($pseudo-element == all) {
    &:after,
    &:before {
      content: $content;
      position: $position;
      display: block;
      @content;
    }
  }
  @else {
    &:#{$pseudo-element} {
      content: $content;
      position: $position;
      display: block;
      @content;
    }
  }
}