.error-page {
  @include flex($direction: column, $type: center);
  height: 100vh;
  width: 100%;
  background-color: $outer_dark_color;
}
.error-page-content {
  text-align: center;

  &__main {
    @include font-style($font_avenir_black, 250px, $prime_color, 1);
    display: flex;
    align-items: center;
    margin-bottom: 40px;


    &--404 {
      &::before,
      &::after{
        content: '4';
      }
    }
    &--403 {
      &::before {
        content: '4';
      }
      &::after {
        content: '3';
      }
    }
  }
  &__main-logo {
    width: 250px;
    img {
      max-width: 100%;
    }
  }
  &__title {
    @include font-style($font_avenir_heavy, 52px, $white, 1);
    margin-bottom: 25px;
  }
  &__text {
    @include font-style($font_avenir_regular, 18px, $gray, 1.4);
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

}
