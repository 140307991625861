@import "defaults";

.brands__wrap--more {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 10px 0 0;
  background: url("../../images/landings/commissions_tab/header-blob.png") no-repeat top right,
              url("../../images/landings/commissions_tab/header-gray-blob.png") no-repeat top right;

  .back-btn {
    display: none;
  }

  .header {
    margin-bottom: 63px;
  }

  .brands {
    margin: 0 auto;
  }

  .slider.slider__brands {
    max-width: 320px;
    padding: 0;

    .slick-dots {
      position: absolute;
      bottom: unset;
      top: 306px;
      width: 100%;
    }

    .slick-arrow {
      top: 279px;
    }

    .brands__item{
      position: relative;

      h2 {
        text-align: left;
        margin: 0 20px 28px;
        letter-spacing: 0;
      }

      .mockup-cont, .logo-cont {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
      }

      .logo-cont {
        margin-bottom: 50px;
      }

      .btn.visit-site {
        margin-bottom: 30px;
      }

      p {
        font-family: $mainSansSerif;
      }

      .expandable-paragraph {
        font-family: $mainSansSerif;
        font-size: 16px;
        line-height: 19px;
        color: $titleBlack;
        margin: 0 20px 20px;

        .hide__dots {
          display: none;
        }

        .hide__more {
          display: inline;
        }
      }

      .expandable-paragraph.hide {
        .hide__dots {
          display: inline;
          transition: display 1s;
        }

        .hide__more {
          display: none;
          transition: display 1s;
        }
      }

      .expand-text__btn {
        margin: 0 20px 30px;
      }

      h3 {
        margin: 0 20px 30px;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: left;
        font-weight: 800;
      }

      .proj-info__info {
        display: flex;
        flex-direction: column;
        margin: 0 20px 50px;
        box-shadow: 0 15px 25px $slideBoxShadow;
        border-radius: 15px;

        .proj-info__section {
          &:nth-child(even) {
            background: $backgroundLightBlue;
          }

          &:nth-child(odd) {
            background: $defaultWhite;
          }

          &:first-child {
            border-radius: 15px 15px 0 0;
          }

          &:last-child {
            border-radius: 0 0 15px 15px
          }

          & > div {
            padding: 0 20px 20px;

            &:first-child {
              padding-top: 30px;
            }

            &:last-child {
              padding-bottom: 30px;
            }
          }
        }

        .proj-info__subtitle {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 23px;
          font-weight: 600;
        }
      }

      .next-prev {
        display: none;
      }

    }
  }

  .footer {
    padding-top: 0;
    min-height: unset;
  }
}