@import "defaults";

.default__bg-layer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: url("../../images/landings/commissions_tab/header-blob.png")
      no-repeat top right,
    url("../../images/landings/footer-blue-bg--desktop.png") no-repeat bottom -10px
      center,
    url("../../images/landings/footer-gray-bg--desktop.png") no-repeat bottom -20px
      center,
    url("../../images/landings/commissions_tab/header-gray-blob.png") no-repeat
      right top;

  .header {
    padding-top: 10px;
    margin: 0 20px 67px;
    height: unset;
  }

  .text__content {
    max-width: 1200px;
    margin: 20px 15px;
  }

  .footer {
    padding-top: 0;
    min-height: unset;
    background: none;
  }
}
