.footer-nav {
  @include flex;
  margin: 0;
  margin-left: auto;
  padding: 0;
  list-style: none;

  @include media('<old') {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
  }

  &__item {
    margin-left: 25px;


    &:first-child {
      margin-left: 0;
    }
    @include media('<old') {
      margin-left: 0;
      margin-bottom: 20px;

    }
  }

  &__link {
    @include font-style($font_avenir_medium, 14px, $white, 1);
    transition: color .3s ease, transform .2s ease;
    display: block;
    &:hover,
    &:active{
      transform: scale(1.1);
      color: $prime_color;
    }
    &:active{
      transform: scale(.9);

    }

  }
}