.article {
  padding-bottom: 45px;
  @include media('<568px') {
    padding-bottom: 0;
  }
  &__image {
    height: 500px;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 30px;

    @include media('<old') {
      height: 310px;
    }
    @include media('<568px') {
      height: 215px;
    }
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
  &__title {
    @include font-style($font_avenir_black, 32px, $white, 1.3);
    margin-bottom: 25px;

    @include media('<568px') {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  &__text {
    color: #B1B1B1;
    line-height: 24px;
    
    a{
      color: $prime_color;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $prime_color_hover;
      }
    }
  }
  &__btn {
    width: 175px;
    margin: 0 auto;
    margin-top: 45px;
    display: block;

    @include media('<568px') {
      width: 100%;
      margin-top: 20px;
    }
  }
}