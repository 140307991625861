.about-section {
  $image-path: '../../../../assets/images/outer-pages/';
  background: $dark_tile;
  padding-top: 60px;
  padding-bottom: 60px;


  &__wrap{
    @include flex($align-items: center);
  }

  &__logo {
    margin-left: 75px;
    width: 380px;
    height: 410px;
    background: url($image-path + "big-logo.svg") no-repeat center / contain;
    
    @include media('<old') {
      display: none;
    }
  }

  .section-title{
    
    &--left{
      
      @include media('<old') {
        text-align: center;
      }
    }
  }
}