body  {
  overflow-x: hidden !important;
  background-color: $outer_dark_color;
  min-height: 100vh;
}
.main-wrapper {
  @include flex($direction: column);
  min-height: 100vh;
  background-color: $outer_dark_color;
  @include font-style($font_avenir_regular, 16px, #fff, 1.4);
  overflow-x: hidden;
  overflow-y: visible;

  @include media('<old') {
    padding-bottom: 40px;
  }
  .ant-spin-container {
    &::after {
      background: transparent;
    }
  }
  .ant-spin-dot-item {
    background-color: $prime_color;
  }
}

.container {
  width: 1240px;
  max-width: 100%;
  margin: 0 auto;

  @include media('<HD') {
    width: 100%;
    padding: 0 15px;
  }

  &--small {
    width: 1120px;
  }

}

.section-title {
  text-align: center;
  text-transform: capitalize;
  @include font-style($font_avenir_black, 46px, #fff, 1.4);
  font-weight: normal;
  margin-bottom: 30px;

  @include media('<old') {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  &--left {
    text-align: left;
  }
  &--dark {
    color: $dark;
  }
}

.page-title {
  @include font-style($font_avenir_black, 46px, $white, 1.3);
  margin: 0;
  margin-bottom: 40px;

  @include media('<laptop-small') {
    font-size: 36px;
    margin-bottom: 20px;
  }
  @include media('<old') {
    font-size: 24px;
    text-align: center;
    margin-bottom: 25px;
  }

  &--center {
    text-align: center;
  }
}

.top-page-indent {
  padding-top: 100px;

  @include media('<laptop-small') {
    padding-top: 50px;
  }
  @include media('<old') {
    padding-top: 25px;
  }
}

.page-text-content {
  @include font-style($font_avenir_regular, 14px, $gray, 24px);
  max-width: 800px;
  margin: 0 auto;

  @include media('<568px') {
    font-size: 12px;
    line-height: 18px;
  }

  p+p{
    margin-top: 15px;
  }
}