.header-nav {
  $image-path: '../../../../../assets/images/outer-pages/';
  margin-left: auto;

  @include media('<old') {
    margin-right: auto;
  }

  &__list {
    @include flex;
    margin: 0;
    padding: 0;
    list-style: none;


    @include media('<old') {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__item {
    margin-left: 20px;

    @include media('<old') {
      margin-left: 0;
      margin-bottom: 45px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__link {
    display: block;
    color: #fff;
    font-family: $font_avenir_medium;
    transition: color .3s ease, transform .2s ease-out;

    @include media('<old') {
      @include flex($align-items: center);
      font-size: 18px;
    }

    &::before {
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 10px;
      flex: none;

      @include media('<old') {
        content: '';
      }
    }

    &--testimonials {
      &::before {
        background-image: url($image-path + 'header-nav-icon-testiomonials.svg');
      }
    }

    &--faq {
      &::before {
        background-image: url($image-path + 'header-nav-icon-faq.svg');

      }
    }

    &--contacts {
      &::before {
        background-image: url($image-path + 'header-nav-icon-contacts.svg');

      }
    }
    &--dashboard {
      &::before {
        background-image: url($image-path + 'header-nav-icon-dashboard.svg');

      }
    }

    &:hover {
      color: $prime_color;
    }

    &:active {
      transform: scale(1.075);
    }
  }
}