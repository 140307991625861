.more-page-item {
  $image-path: '../../../../assets/images/outer-pages/';
  border-radius: $border-radius;
  box-shadow: $tile-shadow;
  background: $dark_tile;
  padding-left: 60px;
  padding-top: 40px;
  position: relative;
  height: 195px;
  transition: background-color .2s linear;

  @include media('<old') {
    padding: 30px 20px;
    height: 165px;
    margin-bottom: 10px;
  }


  &__title {
    @include font-style($font_avenir_black, 24px, $white, 1);
    font-weight: normal;
    margin-bottom: 20px;
    transition: color .2s linear;
  }
  &__icon {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include media('<laptop-small') {
      transform: scale(.6);
    }

    @include media('<old') {
      transform: none;
    }
  }
  &__btn {
    font-size: 18px;
    padding: 15px 30px;
    border: none;

    @include media('<568px') {
      font-size: 16px;
      padding: 10px 25px;
    }
  }
  &--0 {
    .more-page-item{
      &__icon {
        width: 195px;
        height: 195px;
        right: -30px;
        bottom: -40px;
        background-image: url($image-path + 'beverages-item-1.png');

        @include media('<laptop-small') {
          right: -50px;
          bottom: -60px;
        }
        @include media('<old') {
          right: -10px;
          bottom: -30px;
        }
        @include media('<568px') {
          width: 167px;
          height: 167px;
          bottom: -20px;
        }
        @include media('<375px') {
          right: -50px;
        }
      }
    }
  }
  &--1 {
    .more-page-item{
      &__icon {
        width: 198px;
        height: 198px;
        right: -30px;
        bottom: -25px;
        background-image: url($image-path + 'beverages-item-2.png');
        @include media('<laptop-small') {
          right: -50px;
          bottom: -60px;
        }
        @include media('<old') {
          right: -10px;
          bottom: -30px;
        }
        @include media('<568px') {
          width: 170px;
          height: 170px;
          bottom: -20px;
        }
        @include media('<375px') {
          right: -35px;
        }
      }
    }
  }
  &--2 {
    .more-page-item{
      &__icon {
        width: 195px;
        height: 195px;
        right: -50px;
        bottom: -15px;
        background-image: url($image-path + 'beverages-item-3.png');
        @include media('<laptop-small') {
          right: -50px;
          bottom: -60px;
        }
        @include media('<old') {
          right: -10px;
          bottom: -30px;
        }

        @include media('<568px') {
          width: 168px;
          height: 168px;
          bottom: -20px;
        }
        @include media('<375px') {
          right: -70px;
        }
      }
    }
  }
  &:hover {
    background-color: $prime_color;
    .more-page-item {
      &__title {
        color: $dark;
      }
    }
  }
}