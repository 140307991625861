.commission-content {
  @include flex($direction: column);
  padding-bottom: 40px;
  &__list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 800px;
    max-width: 100%;
  }

  &__item {
    @include font-style($font_avenir_medium, 18px, $white, 26px);
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;

    @include media('<568px') {
      font-size: 14px;
      line-height: 24px;
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 8px;
      background-color: $prime_color;

      @include media('<568px') {
        top: 6px;
      }
    }
  }
  &__btn {
    align-self: center;
    margin-top: 30px;
  }
}