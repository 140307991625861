@mixin placeholder-font-family($font-family) {
  &::-webkit-input-placeholder {
    font-family: $font-family;
  }
  &::-moz-placeholder {
    font-family: $font-family;
  }
  &:-moz-placeholder {
    font-family: $font-family;
  }
  &:-ms-input-placeholder {
    font-family: $font-family;
  }
}
