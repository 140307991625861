.how-it-works-slider {
  padding: 50px 0;
  padding-bottom: 25px;
  width: 992px;

  .slick-list {
    width: calc(100% + 60px);
    margin-left: -30px;
    padding-left: 30px;
  }

}