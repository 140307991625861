.contacts {
  &__title {
    margin-bottom: 10px;
  }
  &__sub-title {
    @include font-style($font_avenir_medium, 18px, $white, 24px);
    text-align: center;
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 30px;


    @include media('<old') {
      font-size: 16px;
    }
  }
}