.main-banner-slider{
  padding: 150px 0;
  overflow: hidden;

  @include media('<HD') {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 100px 15px;
    padding-bottom: 150px;
  }

  @include media('<old') {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  @include media('<568px') {
    padding-bottom: 40px;
  }

  .slick-list {
    overflow: visible;
  }
  .slick-dots{
    @include flex($justify-content:center);
    display: flex !important;
    @include absolute-center(true, false);
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    bottom: 0;
    font-size: 0;

    button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(#A9A9A9, .2);
      padding: 0;
      border: none;
      transition: background-color .2s ease, width .2s;
      cursor: pointer;

      @include media('<old') {
        width: 16px;
        height: 6px;
        border-radius: 20px;

      }
    }

    li{
      +li {
        margin-left: 5px;
      }
      &.slick-active {
        button {
          background-color: $prime_color;

          @include media('<old') {
            width: 32px;
            height: 6px;
          }
        }
      }
    }



  }
}