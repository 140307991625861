.how-it-works-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 55px;

  &__item {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }

    &--active {
      .how-it-works-list{
        &__button {
          color: $dark;
          transform: scale(1.075);

          &:active,
          &:focus {
            color: $dark;
          }
        }
      }
    }
  }
  &__button {
    display: inline-block;
    @include font-style($font_avenir_heavy, 28px, rgba(0, 0, 0, 0.5), 1);
    border: none;
    cursor: pointer;
    background: none;
    padding: 0;
    transition: color .3s ease, transform .3s ease-out;

    &:active,
    &:focus {
      outline: none ;
      color: rgba(0, 0, 0, 0.75);
    }

    &:hover {
      color: $dark;
    }
  }
}