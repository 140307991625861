@import "defaults";

@include large-mobile {
  .portfolio {
    .slider {
      & .slick-arrow {
        width: 66px;
        height: 66px;
      }
    }
  }
}

@include tablet {
  .main-wrap {
    max-width: 1440px;
    margin: 0 auto;
    padding: 55px 0 0;
    background: url("../../images/landings/wrapper_bg/bg_elem_1--desktop.png") no-repeat right 40px top 55px,
                url("../../images/landings/wrapper_bg/bg_elem_2--desktop.png") no-repeat right top,
                url("../../images/landings/wrapper_bg/bg_elem_3--desktop.png") no-repeat right top,
                url("../../images/landings/wrapper_bg/bg-elem_how-it-works_section_desktop.png") no-repeat center top 1280px;

  }

  .header, .commissions__wrap .header {
    width: calc(100% - 140px);
    margin: 0 70px 600px;
    height: 60px;

    &__logo-cont {
      flex-shrink: 0;
    }

    &__mobile-menu {
      display: none;
    }

    &__desktop-menu {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      flex-shrink: 1;
      flex-grow: 1;
      max-width: 820px;
    }

    &__menu {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 700px;
      align-items: center;
      vertical-align: center;
      list-style-type: none;
      text-decoration: none;
      overflow: hidden;

      &-item {
        position: relative;
        float: right;
        height: 60px;
        line-height:  60px;
        min-width: 60px;
        margin: 0 6px;
        text-align: center;
        cursor: pointer;
      }

      &-link {
        position: relative;
        display: inline-block;
        color: $defaultWhite;
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;

        &::selection {
          background: $mainOrange;
        }

        &:after {
          display: inline-block;
          position: absolute;
          bottom: 18px;
          left: 0;
          content: '';
          width: 100%;
          height: 0;
          border-bottom: solid 2px;
          border-image: $underlineGradient 1;
          border-radius: 6px;
          opacity: 0;
          transition: linear 0.2s;
        }

        &:hover:after {
          opacity: 1;
          transition: linear 0.2s;
        }

        .language-flag {
          height: 30px;
          padding: 4px;
        }
      }
    }

    .login-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;

      .btn {
        width: 125px;
        height: 45px;
        padding: 10px 14px 10px;

        &__content-wrap {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin: 0 0 3px 7px;
          }
        }
      }

      .sign-in {
        color: $defaultWhite;
        border: 1px solid $opaqueGray2;
        margin-right: 15px;

        &:hover {
          padding: inherit;
        }
      }

      .sign-up {

      }
    }
  }

  .welcome {
    max-width: 520px;
  }

  .how-it-works {
    background: unset;
  }

  .contact {
    position: relative;
    background: url("../../images/landings/pixel-world-bg--desktop.png") no-repeat center;
    background-size: contain;

    .btn__scroll--up {
      position: absolute;
      right: 53px;
      bottom: 165px;
      box-shadow: 0 -15px 50px $slideBoxShadow;

      &:hover {
        box-shadow: 0 -15px 50px $btnBoxShadowTint;
      }

      img {
        transform: rotate(180deg);
      }
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    padding: 0 100px 20px;
    background: url("../../images/landings/footer-blue-bg--desktop.png") no-repeat bottom,
    url("../../images/landings/footer-gray-bg--desktop.png") no-repeat bottom;

    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      height: 50px;
    }

    &__info {
      margin: 0;
      order: unset;
    }

    &__menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      height: 30px;
      width: 75%;

      &-item {
        margin: 0 15px;
        height: 30px;
        line-height: 30px;

        a {
          color: $defaultWhite;
          display: block;
          height: 100%;
          line-height: unset;

          &::selection {
            background: $mainOrange;
          }

          &::after {
            bottom: 4px;
          }
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .footer {
    &__menu {
      &-item {
        margin: 0 15px;
      }
    }
  }
}

@include desktop {

  .main-wrap {
    background: url("../../images/landings/wrapper_bg/bg_elem_1--desktop.png") no-repeat right 40px top 55px,
                url("../../images/landings/wrapper_bg/bg_elem_2--desktop.png") no-repeat right top,
                url("../../images/landings/wrapper_bg/bg_elem_3--desktop.png") no-repeat right top,
                url("../../images/landings/wrapper_bg/bg-elem_how-it-works_section_desktop.png") no-repeat center top 1069px;

  }

  .slider {
    max-width: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .header, .commissions__wrap .header {
    width: calc(100% - 300px);
    margin: 0 150px;

    &__menu {
      &-item {
        margin: 0 10px;
      }
    }
  }

  .welcome {
    margin: 75px auto 320px 150px;
    padding: 0;
    max-width: 455px;

    h1 {
      text-align: left;
      font-size: 51px;
      line-height: 65px;
    }
    
    p {
      text-align: left;
    }

    .btn__default {
      margin-left: 0;
    }

    .btn__scroll--down {
      display: block;
      position: absolute;
      top: 595px;
      left: calc(100%/2 - 33px);
    }
  }

  .our-benefits {
    margin: 0 150px;
    max-width: unset;

    .slider {
      margin-bottom: 50px;

      &__item {
        height: unset;

        &:nth-child(2) .slider__item-content{
          border-radius: 0 0 30px 30px;
        }

        &:nth-child(3) .slider__item-content{
          border-radius: 30px 0 30px 0;
        }
      }

      &__item-content {
        max-width: unset;
        width: 360px;
        padding: 43px 20px 35px;
      }
    }
  }

  .how-it-works {
    padding: 0 150px;
    margin: 190px 0 0;

    .slider {
      margin: 26px auto 82px;

      &__item {
        min-width: unset;
      }

      &__item {
        height: unset;
        min-height: unset;

        &:not(:last-child)::after {
          position: absolute;
          content: url("../../images/landings/poly-arrow-orange.svg");
          right: -20px;
          top: 146px;
        }
      }

      &__item-content {
        width: 262px;
        padding: 60px 23px 35px;
      }
    }
  }

  .commissions {
    padding: 201px 150px 0;

    .slider {
      justify-content: center;

      &__item {
        padding-left: 0;
        padding-right: 0;

        &:nth-child(2) {
          padding-top: 119px;

          .slider__item-content {
            z-index: 1;
            padding: 126px 44px 63px;
            height: 418px;
            box-shadow: 0 20px 50px $btnBoxShadowTint;

            &__img-plaque {
              top: -63.5px;
              box-shadow: 0 20px 50px $btnBoxShadowTint;
            }
          }
        }
      }

      &__item-content {
        height: 375px;
      }
    }
  }

  .portfolio {
    padding: 0 150px;
    margin: 0 auto 59px;

    .slider {
      flex-wrap: wrap;

      &__wrap {
        padding: 0;

        &:nth-child(4n), &:nth-child(4n - 1) {
          .slider__portfolio-item {
            &__logo {
              z-index: 1;
              order: 2;
              margin: 0 0 0 23px;

              &:after {
                bottom: 74px;
                left: -23px;
                border-right: 12px solid $defaultWhite;
                border-left: 12px solid transparent;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
              }
            }
          }
        }
      }

      &__portfolio-item {
        flex-direction: row;
        max-width: 545px;

        &__logo {
          min-width: 229px;
          padding: 0 42px;
          margin: 0 23px 0 0;

          &:after {
            bottom: 74px;
            right: -23px;
            border-left: 12px solid $defaultWhite;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
          }
        }

        &__content {
          height: 180px;

          .btn {
            align-self: flex-start;
          }
        }
      }
    }
  }

  .contact {
    padding: 50px 0;
    min-height: 560px;
    margin: 0;

    & > span {
      width: 370px;
    }

    &__form {
      max-width: 554px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      & > div {
        width: 261px;
      }

      .textarea-wrap {
        width: 554px;
      }
    }

    .btn__scroll--up {
      display: block;
    }
  }
}