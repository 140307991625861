.income-button {
  @include flex($display: inline-flex, $justify-content: center);
  @include font-style($font_avenir_heavy, 16px, $dark, 25px);
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: $prime_color;
  transition: background-color .3s ease;
  min-width: 135px;
  padding: 10px 20px;
  border-radius: $border-radius;

  @include media('<old') {
    @include font-style($size: 12px, $line-height: 18px);
    padding: 10px 30px;
    min-width: 85px;
    border-radius: 4px;
  }

  &:hover {
    color: $dark;
    background-color: $prime_color_hover;
  }

  &:active {
    background-color: $prime_color_active;
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: .3;
  }

  &--border {
    background: none;
    border-color: $prime_color;
    color: $white;

    &:hover {
      color: $dark;
    }
  }

  &--dark {
    color: $white;
    background-color: $dark_soft;

  }

  &--small {
    @include font-style($font_avenir_medium, 14px, $dark, 18px);
    padding: 10px;
    border-radius: 100px;
  }
  &--small-border{
    color: $prime_color;
    border-color: $prime_color;
    background-color: transparent;
  }

  &--full {
    width: 100%;
  }
}