@import "defaults";

.tournaments-list {
  flex-grow: 1;
  padding-top: 0;
  min-width: 70%;

  &__post {
    width: auto;
    margin-bottom: 30px;
    border-radius: 15px;
    margin-left: 0;
    margin-right: 0;

    .ant-card-cover {
      justify-content: center;
      display: flex;
    }
    .ant-card-body {
      padding: 0;
    }

    &-img {
      width: 95%;
      margin-top: 25px;
      border-radius: 8px;
    }

    &-content {
      width: 95%;
    }

    &-content-text {
      margin-top: 20px;
      font-weight: 400;
      color: black;
      font-size: 17px;
    }

    .ant-card-body {
      display: flex;
      justify-content: center;
    }

    .prize-container {
      display: flex;

      &-lavr {
        vertical-align: middle;
        text-align: center;
        font-size: 1.4em;
        font-weight: 700;
        height: 40px;
        width: 40px;
        background-image: url("../../images/landings/prize-background.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40px 40px;
      }
    }
  }
}
@include tablet {
  .tournaments-list {
    &__post {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
}
@include large-mobile {
  .tournaments-list {
    &__post {
      .ant-card-body {
        padding: 24px 0;
      }
    }
  }
}
