// MIXINS
@mixin resetButton {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}


@mixin large-mobile {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

// FONTS
$mainSansSerif: Roboto;
$titleFont: foco;
$subtitleFont: foco;
$focoReg: foco;

// COLORS
$defaultWhite: #fff;
$mainOrange: #F47D20;
$titleBlack: #0E0303;
$backgroundGray: #edf4ff;
$dotBlue: #0066FF;
$opaqueBlack: rgba(0, 0, 0, 0.2);
$opaqueBlack2: rgba(0, 0, 0, 0.5);
$opaqueGray: rgba(240, 240, 240, 0.4);
$opaqueGray2: rgba(213, 213, 213, 0.4);
$opaqueGray3: rgba(130, 149, 166, 0.2);
$borderBlue: rgba(0, 102, 255, 0.6);
$modalBackground: rgba(0, 102, 255, 0.8);
$slideBoxShadow: rgba(0, 102, 255, 0.15);
$backgroundLightBlue: rgba(0, 102, 255, 0.03);
$btnBoxShadowTint: rgba(244, 125, 32, 0.3);
$mainButtonGradient: linear-gradient(75.96deg, #F17D32 3.27%, #F7C443 98.82%);
$mainButtonGradientActive: linear-gradient(170.96deg, #f7cc4c 5.27%, #f18e36 96.82%);
$hollowBtnHoverGradient: linear-gradient(90deg, #F17D32 24.44%, #F7C443 85%);
$underlineGradient: linear-gradient(90deg, #F47D20 -0.85%, #F4B920 51.22%);
$dropdownMenuShadow: rgba(204, 215, 234, 0.8);