.news-preview {
  position: relative;
  height: 400px;
  padding: 40px;
  padding-top: 185px;

  @include media('<568px') {
    @include flex($align-items: flex-end);
    height: 225px;
    padding: 20px;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: transform .3s ease;

    }

    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 310px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.724958) 30.2%, #000000 100%);

      @include media('<568px') {
        height: 185px;
      }
    }
  }

  &__info {
    @include flex($display: inline-flex,$direction: column);
    position: relative;
    z-index: 2;

    @include media('<568px') {
      width: 100%;
    }
  }

  &__title {
    @include font-style($font_avenir_black, 24px, $white, 1.3);
    transition: color .3s ease;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 62px;
    max-width: 270px;
    margin-bottom: 5px;


    @include media('<568px') {
      font-size: 18px;
      line-height: 21px;
      max-height: 43px;
      margin-bottom: 5px;
    }
    &:hover {
      color: $prime_color;
    }
  }

  &__text {
    @include font-style($font_avenir_regular, 16px, #B1B1B1, 24px);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 49px;
    max-width: 220px;

    @include media('<568px') {
      font-size: 12px;
      line-height: 15px;
      max-height: 32px;
    }
  }

  &__btn {
    width: auto;
    align-self: flex-start;

    @include media('<568px') {
      width: 100%;
    }
  }

  &:hover {
    .news-preview {
      &__image {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}