$outer_dark_color: #1D1E1F;
$dark_tile: #141415;
$dark_soft: #1D222F;

// prime color & it states
$prime_color: #B3E8C3;
$prime_color_hover: #7DD397;
$prime_color_hover: #7DD397;
$prime_color_active: #6CD68C;

$dark: #000000;
$white: #ffffff;
$gray: #B1B1B1;
$red: #af0202;