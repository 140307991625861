.contact-box {
  $image-path: '../../../../assets/images/outer-pages/';

  background-color: $dark_tile;
  box-shadow: $tile-shadow;
  border-radius: $border-radius;
  padding: 30px 90px 40px;
  position: relative;
  overflow: hidden;

  @include media('<old') {
    padding: 30px 20px;
  }

  &__title {
    @include font-style($font_avenir_black, 24px, $white, 28px);
    margin-bottom: 15px;
    
    @include media('<old') {
      display: none;
    }
  }
  &__text {
    color: $gray;

    @include media('<laptop-small') {
      max-width: 400px;
    }
  }
  &__image {
    width: 349px;
    height: 206px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: url($image-path + "contact-image.png") no-repeat center / contain;

    @include media('<old') {
      width: 192px;
      height: 110px;
    }
    @include media('<375px') {
      right: -30px;
    }
  }
}