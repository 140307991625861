.lang-switcher-wrapper {
  .ant-menu {
    background: $dark_tile;
    box-shadow: $tile-shadow;
    width: 165px;
    border-radius: $border-radius;
    border: none;
    padding: 5px 20px;
    margin-left: -20px;

    .ant-menu-item {
      + .ant-menu-item {
        border-top: 2px solid #303030;
      }

      padding: 0;
      margin: 0;
      height: auto;
      background: none !important;

      &.ant-menu-item-active {
        border-color: #303030 !important;
      }
    }
  }

}

.lang-switcher-item {
  @include flex($align-items: center);
  @include font-style($font_avenir_medium, 16px, $alwaysWhite, 1);
  cursor: pointer;
  border: none;
  background: none !important;
  padding: 0;
  height: 50px;
  width: 100%;
  transition: color .2s ease;

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    margin: 0;
  }

  &:hover {
    color: $prime_color_hover;
  }

  &:active {
    color: $prime_color_active;
  }
}

.lang-switcher-current {
  $image-path: '../../../../assets/images/';
  position: relative;
  padding-right: 15px;

  &__arrow {
    width: 10px;
    height: 10px;
    @include absolute-center(false, true);
    right: 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url( $image-path + "lang-arrow-down.svg") no-repeat center / contain;
      transition: transform .2s ease;
    }
  }

  &.ant-dropdown-open {
    .lang-switcher-current{
      &__arrow {
        &::before{
          transform: rotate(180deg);
        }
      }
    }
  }
}