.auth-wrap {
  $image-path: '../../../../assets/images/outer-pages/';
  background: $prime_color;
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  
  @include media('<old') {
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;
    background: $dark_tile;
  }


  &__box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include media('<old') {
      position: relative;
      overflow: visible;
      padding: 0 25px;
      padding-top: 40px;
    }
    @include media('<375px') {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__content {
    @include flex;
    background-color: $dark_tile;
    width: 485px;
    height: 100vh;
    color: $white;
    padding: 60px;
    overflow-y: auto;

    @include media('<old') {
      width: 100%;
      height: auto;
      overflow: visible;
      padding: 25px 10px;
    }

    @include media('<375px') {
      padding: 25px 0;
    }

  }

  &__bg {
    position: absolute;
    right: 0;
    top: 0;
    background: url($image-path + 'auth-image.jpg') no-repeat center / cover;
    width: calc(100% - 485px);
    height: 100%;
  }

  &__close {
    position: absolute;
    right: 35px;
    top: 35px;
    width: 16px;
    height: 16px;
    background: url($image-path + "close-icon-dark.svg") no-repeat center / contain;
    cursor: pointer;
    transition: transform .3s ease;
    display: block;


    @include media('<old') {
      right: 10px;
      top: 10px;
      background-image: url($image-path + "close-icon-white.svg");
    }
    &:hover {
      transform: rotate(90deg) scale(1.25);
    }
  }

}

.auth-box {
  width: 100%;
  margin: auto;

  &__title {
    @include font-style($font_avenir_black, 24px, $white, 1.2);
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  &__sub-title {
    @include font-style($font_avenir_medium, 18px, $white, 1.2);
    text-align: center;
  }
}

.success-sign {
  &__title {
    @include font-style($font_avenir_black, 24px, $white, 1.2);
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  &__text {
    @include font-style($font_avenir_medium, 18px, $white, 1.4);
    text-align: center;
    max-width: 310px;
    margin: 0 auto;
    margin-bottom: 45px;
  }
}

.auth-nav {
  @include flex;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  list-style: none;
  flex: 1;

  &__item{
    flex: 1;

    &:first-child {

      .auth-nav {
        &__link {
          border-radius: 4px 0 0 4px;
          border-right: none;
        }
      }
    }
    &:last-child {

      .auth-nav {
        &__link {
          border-radius: 0 4px 4px 0;
          border-left: none;
        }
      }
    }
  }
  &__link {
    @include font-style($font_avenir_heavy, 10px, $prime_color, 28px);
    text-align: center;
    display: block;
    transition: background-color .3s ease, color .3s ease;
    border: 1px solid $prime_color;


    &:hover {
      color: $prime_color;
    }

    &--active {
      background-color: $prime_color;
      color: #303030;

      &:hover {
        color: #303030;

      }
    }
  }
}