.how-it-works {
  background-color: $prime_color;
  padding-top: 120px;
  padding-bottom: 140px;

  @include media('<old') {
    padding: 0 15px;
    padding-top: 95px;
    padding-bottom: 100px;
  }
  @include media('<old') {
    padding-bottom: 60px;
  }

  &__wrap {
    @include flex($direction: column);
    position: relative;
  }

  &__title {
    margin-bottom: 15px;

    @include media('<old') {
      margin-bottom: 5px;
    }
  }
  &__sub-title {
    @include font-style($font_avenir_regular);
    font-weight: normal;
    margin: 0;
    color: #303030;
  }

  &__btn {
    width: 245px;
    margin-top: 70px;

    @include media('<old') {
      margin: 0 auto;
    }
  }
}