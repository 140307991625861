@import "defaults";

@include tablet {
  .commissions__wrap {
    min-height: 100vh;
    padding: 55px 0 0 0;
    background: url("../../images/landings/commissions_tab/header-bg-large-blue.svg") no-repeat top right,
                url("../../images/landings/commissions_tab/header-bg-large-gray.svg") no-repeat top right,
                url("../../images/landings/commissions_tab/Vector 2.svg") no-repeat top 293px left,
                url("../../images/landings/commissions_tab/Vector 2.1.svg") no-repeat top 490px left,
                url("../../images/landings/commissions_tab/Ellipse 2.4.svg") no-repeat top 350px right 0,
                url("../../images/landings/footer-gray-bg--desktop.png") no-repeat bottom center,
                url("../../images/landings/footer-blue-bg--desktop.png") no-repeat bottom center,
                url("../../images/landings/commissions_tab/Ellipse 4.svg") no-repeat top 727px left;
    .header {
      margin-bottom: 82px;
    }

    .commissions {
      flex-grow: 1;
      padding-top: 0;

      .slider {
        &__item:nth-child(2) {
          padding-top: 111px;

          .slider__item-content {
            padding-top: 154px;
          }
        }

        &__item-content {
          padding-top: 135px;
        }
      }
    }

    .footer {
      padding-top: 20px;
      width: 100%;
      background: none;
    }
  }
}

@include desktop {
  .commissions__wrap {
    max-width: 1440px;
    margin: 0 auto;

    .commissions {
      max-width: 1140px;
      padding-left: 0;
      padding-right: 0;
    }

    .footer {
      max-width: 1440px;
    }
  }
}
