.testimonials-preview {
  padding: 50px 0;
  position: relative;

  &__wrap{
    @include flex($direction: column)
  }
  &__btn {
    width: 240px;
    align-self: center;
    margin-top: 65px;

    @include media('<old') {
      margin-top: 30px;
    }
  }

  .slick-slider{
    width: calc(100% + 30px);
  }
}