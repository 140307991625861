.main-wrapper {
  .footer{
    margin-top: auto;
    &__bottom {

      @include media('<old') {
        width: 100%;
        @include flex($align-items:center);
        padding-bottom: 15px;
      }
    }
    &__wrap {
      @include flex($align-items: center, $justify-content: space-between);
      height: 90px;

      @include media('<old') {
        flex-direction: column-reverse;
        height: auto;
        padding-top: 50px;
      }
    }
  }
  .copyright {
    margin: 0;

    @include media('<old') {
      margin: 0 auto;
    }
    @include media('<568px') {
      font-size: 10px;
      margin: 0;
      margin-left: auto;
    }
  }
}