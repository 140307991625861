.top-testimonial-item {

  @include media('<old') {
    width: 280px;
    margin-right: 20px;
  }

  &__card {
    background-color: $dark_tile;
    box-shadow: $tile-shadow;
    padding: 30px;
    border-radius: $border-radius;
    min-height: 170px;
    position: relative;



    &::before {
      content: "";
      position: absolute;
      left: 40px;
      bottom: -20px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      border-color: $dark_tile transparent transparent transparent;
    }
  }
  &__title {
    margin: 0;
    padding-bottom: 15px;
    margin-bottom: 10px;
    @include font-style($font_avenir_black, 18px, $white, 1);
    position: relative;

    @include pseudo-element() {
      background-color: $prime_color;
      width: 95px;
      height: 4px;
      bottom: 0;
      left: 0;
    }
  }
  &__info {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 44px;

    @include media('<old') {
      display: block;
      height: auto;
    }
  }
  &__brand {
    margin: 0;
    margin-top: 50px;
    @include font-style($font_avenir_black, 18px, $white, 24px);

    @include media('<old') {
      font-size: 14px;
      margin-top: 40px;
    }
  }
  &__link {
    @include font-style($font_avenir_medium, 12px, $prime_color, 1);
    margin-top: 5px;
    display: inline-block;
    text-decoration: none;
    transition: color .2s ease;

    &:hover {
      color: $prime_color_hover;
    }
  }
  
  &:hover{
    .top-testimonial-item{
      &__info {
        display: block;
        height: auto;
      }
    }
  }
}