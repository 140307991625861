.main-wrapper {
  .header {
    position: relative;
    z-index: 999;

    @include media('<old') {
      background: $dark_tile;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    }

    &__wrap {
      @include flex($align-items: center);
      padding-top: 35px;

      @include media('<old') {
        padding-top: 0;
        height: 70px;
        background: $dark_tile;
        position: relative;
        z-index: 2;
      }

      .income-button {
        margin-left: 30px;

        @include media("<568px") {
          margin-left: auto;
        }
      }
    }

    &__lang {
      margin-left: 30px;
    }

    &__mobile-drop {
      @include flex($type: center, $direction: column);
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      border-top: 1px solid $prime_color;
      background: $dark_tile;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
      padding: 50px 0;
      opacity: 0;
      visibility: hidden;
      filter: alpha(opacity(0));
      transition: all .3s ease;

      &--opened {
        opacity: 1;
        visibility: visible;
        filter: alpha(opacity(100));
      }
    }

    &--logged {


      .main-logo {

        @include media('<old') {
          margin: 0;
          margin-left: auto;
        }
      }
    }
  }

  .main-logo {
    display: block;

    @include media('<old') {
      width: 100px;
    }

    img {
      max-width: 100%;
    }
  }
}