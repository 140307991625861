.main-banner-item{
  $image-path: '../../../../assets/images/outer-pages/';
  position: relative;
  &__title{
    margin: 0;
    margin-bottom: 15px;
    @include font-style($font_avenir_black, 56px, $white, 1);
    @include media('<old') {
      font-size: 32px;
      line-height: 1.2;
    }
  }
  &__text {
    margin: 0;
    margin-bottom: 15px;

    @include font-style($size: 24px, $color: $gray, $line-height: 33px);
    max-width: 360px;

    @include media('<old') {
      font-size: 16px;
      line-height: 1.2;
      max-width: 205px;
    }
  }
  &__image {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &--preview {
      width: 567px;
      height: 446px;
      right: 100px;
      top: -50px;
      background-image: url($image-path + 'main-slider-img-1.svg');

      @include media('<HD') {
        right: -40px;
      }
      @include media('<old') {
        width: 325px;
        height: 245px;
        top: -10px;
        right: auto;
        left: 290px;
      }
      @include media('<568px') {
        display: none;
      }
    }
  }


  &__join {

    @include media('<old') {
      width: 150px;
    }
  }
}