.login-buttons {
  @include flex;
  margin-left: 20px;

  @include media('<old') {
    margin-left: auto;
  }


  &__btn {
    margin-left: 20px;
    @include media('<568px') {
      margin-left: 10px;
      padding-left: 0;
      padding-right: 0;
      min-width: 75px;
    }
    @include media('<375px') {
      min-width: 65px;
    }
    &:first-child{
      margin-left: 0;
    }
  }
}