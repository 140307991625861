.faq-list {
  .ant-col {
    padding-bottom: 50px;

    @include media('<old') {
      padding-bottom: 10px;
    }
  }
}
.faq-item {
  transition: transform .3s ease;

  @include media('<old') {
    padding: 20px;
    border-radius: $border-radius;
    background-color: $dark_tile;
  }

  &__title {
    @include font-style($font_avenir_black, 24px, $white, 1.3);
    margin: 0;
    margin-bottom: 10px;
    transition: color .2s ease;

    @include media('<old') {
      font-size: 21px;
      color: $prime_color;
      line-height: 150%;
    }
  }
  &__text {
    color: #D1D5DB;
    line-height: 1.4;
    transition: color .2s ease;

    @include media('<old') {
      color: $white;
    }
  }

  &:hover{

    transform: scale(1.05);
    .faq-item {
      &__title{
        color: $prime_color;
      }
      &__text {
        color: $white;
      }
    }
  }
}