.commissions-list {
  padding-bottom: 80px;
  padding-top: 40px;

  @include media('<568px') {
    padding: 0;
  }

  >* {

    @include media('<old') {
      margin-bottom: 30px;
    }
    &:nth-child(1) {

      @include media('<old'){
        order: 2;
      }
    }
    &:nth-child(2) {

      @include media('<old') {
        order: 1;
      }

      .commission-item {
        transform: scale(1.1);
        border: 2px solid $prime_color;
        box-shadow: 0 0 80px -45px $prime_color;

        @include media('<old') {
          transform: none;
        }
      }
    }

    &:nth-child(3) {

      @include media('<old') {
        order: 3;
      }
    }
  }
}