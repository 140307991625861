.auth-form {
  @include flex($direction: column);

  &__item {
    &.ant-form-item {
      margin: 0;
      margin-bottom: 15px;
      background: none;
      box-shadow: none;

      &.ant-form-item-has-error {
        .ant-input {
          border-color: $red;
          background: $outer_dark_color;

          &:hover {
            border-color: $red;

          }

          &:focus {
            border-color: $red;
            box-shadow: none;
          }
        }
        .auth-input-box {
          &__icon {
            path {
              stroke: $red;
            }

            &--user {
              path {
                fill: $red;
              }
            }
            &--contact {
              path {
                stroke: none;
                fill: $red;
              }
            }
          }
        }
      }

      &.ant-form-item-has-success {
        .auth-input-box {
          &__icon {
            path {
              stroke: $prime_color;
            }
            &--user {
              path {
                fill: $prime_color;
              }
            }
            &--contact {
              path {
                stroke: none;
                fill: $prime_color;
              }
            }
          }
        }
      }
      .ant-form-item-explain {
        @include font-style($font_avenir_medium, 11px, $red, 1);
        padding-left: 8px;
        margin-top: 10px;
        min-height: initial;
      }
    }

    &--check {
      &.ant-form-item {
        margin-top: 15px;
      }
      .ant-form-item-explain {
        text-align: center;
      }
    }
    &--recaptcha {
      .ant-form-item-control-input-content {
        @include flex($justify-content: center);
      }
      .ant-form-item-explain {
        text-align: center;
      }
    }
  }

  &__btn {
    width: 100%;
  }

  &__buttons {
    margin-top: 30px;
  }

  &__link {
    @include font-style($font_avenir_regular, 14px, $prime_color, 1);


    &--right-align {
      align-self: flex-end;
    }
    &--decor {
      text-decoration: underline;
      
      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      color: $prime_color_hover;
    }
  }
  &__divider-txt {
    @include font-style($font_avenir_medium, 14px, #828282, 1);
    text-align: center;
    margin: 25px 0;
  }

  &__title {
    @include font-style($font_avenir_black, 24px, $white, 1.2);
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
  }
}

.ant-form-item-control-input {
  min-height: initial;
}