.how-it-works-img {
  $image-path: '../../../../assets/images/outer-pages/';
  position: absolute;
  right: 100px;
  top: -100px;

  &__content {
    width: 338px;
    height: 660px;
    background: url($image-path + "how-it-works-img-1.png") no-repeat center / contain;
    transition: background .2s ease;
  }

  &--0 {
    .how-it-works-img {
      &__conten {
        background-image: url($image-path + "how-it-works-img-1.png");
      }
    }
  }
  &--1 {
    .how-it-works-img {
      &__content {
        background-image: url($image-path + "how-it-works-img-2.png");

      }
    }
  }
  &--2 {
    .how-it-works-img {
      &__content {
        background-image: url($image-path + "how-it-works-img-3.png");

      }
    }
  }
  &--3 {
    .how-it-works-img {
      &__content {
        background-image: url($image-path + "how-it-works-img-4.png");

      }
    }
  }

}
