@mixin btn-primary {
  background: $primaryBtnBg;
  box-shadow: 0px 8px 20px $primaryBtnShadow;
  border-color: $primaryBtnBg;
  &:hover,
  &:focus {
    background: $primaryBtnBgHover;
    border-color: $primaryBtnBgHover;
  }
  &:active {
    background: $primaryBtnBgPressed;
    border-color: $primaryBtnBgPressed;
  }
  &:disabled {
    background: $primaryBtnBgDisabled;
    border-color: $primaryBtnBgDisabled;
    color: $alwaysWhite;
  }
}

@mixin btn-secondary {
  background: $secondaryBtnBg;
  border-color: $secondaryBtnBg;
  color: $primaryMain;
  &:hover,
  &:focus {
    color: $primaryMain;
    background: $white_bg;
    border-color: $primaryMain;
  }
  &:active {
    background: $secondaryBtnBg;
    border-color: $primaryMain;
  }
  &:disabled {
    background: $tertiaryBtnDisabled;
    border-color: $tertiaryBtnDisabled;
    color: $lightsteelblue_bg;
  }
}

@mixin btn-tertiary {
  background: $tertiaryBtnBg;
  box-shadow: 0px 8px 20px $secondaryBtnShadow;
  border-color: $tertiaryBtnBg;
  color: $tertiaryBtnColor;
  &:hover,
  &:focus {
    color: $tertiaryBtnColor;
    background: $alwaysWhite;
    border-color: $tertiaryBtnColor;
  }
  &:active {
    color: $tertiaryBtnColor;
    background: $tertiaryBtnBg;
    border-color: $tertiaryBtnColor;
  }
  &:disabled {
    background: $tertiaryBtnDisabled;
    border-color: $tertiaryBtnDisabled;
    color: $lightsteelblue_bg;
    svg {
      path {
        fill: $lightsteelblue_bg;
      }
    }
  }
}

@mixin btn-default {
  background: $defaultBtnBg;
  box-shadow: 0px 8px 20px $defaultBtnShadow;
  border-color: $defaultBtnBg;
  color: $defaultActiveMain;
  &:hover,
  &:active,
  &:focus {
    color: $defaultActiveMain;
    background: $defaultBtnBgHover;
    border-color: $defaultBtnBgHover;
  }
}

@mixin with-icon {
  padding-left: 24px !important;
  padding-right: 17px !important;
  flex-direction: row-reverse;

  @include btn-tertiary;

  svg {
    display: inline-block;
    margin-left: 10px;
  }
}

@mixin loading {
  &-icon {
    font-size: 20px;
  }
}

.ant {
  &-btn {
    font-weight: 500;
    line-height: 8px;
    text-transform: uppercase;

    &-lg {
      height: 45px;
    }

    &-circle.ant-btn-lg {
      height: 45px;
      width: 45px;
    }

    &-round {
      display: inline-flex;
      align-items: center;

      &.ant-btn-lg {
        font-size: 12px;
        padding: 10px 40px;
        height: 45px;
      }
    }

    &-primary {
      @include btn-primary;
    }

    &-secondary {
      @include btn-secondary;
    }

    &-tertiary {
      @include btn-tertiary;
    }

    &-default {
      @include btn-default;
    }

    &-with-icon {
      @include with-icon;
    }

    &-loading {
      @include loading;
    }
  }
}
