.ant-checkbox-wrapper {
  &.income-checkbox {
    @include flex($justify-content: center);
    @include font-style($font_avenir_regular, 12px, #828282, 16px);


    .ant-checkbox {
      width: 16px;
      height: 16px;
      border: none;

      .ant-checkbox-inner {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #000000;
        border: 1px solid #303030;
        box-sizing: border-box;
        border-radius: 2px;

      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: $prime_color;
          &:after{
            border-color: $prime_color;

          }
        }
      }
      &:hover {
        .ant-checkbox-inner {
          border-color: $prime_color;
          &:after{
            border-color: $prime_color;

          }
        }
      }
    }


    &:hover {
      .ant-checkbox-checked {
        &:after{
          border-color: $prime_color;
        }
      }
    }
  }
}
