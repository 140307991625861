.testimonial {
  @include flex;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #303030;

  @include media('<old') {
    display: block;
    padding: 20px;
    margin: 0;
    border: none;
    background: $dark_tile;
    border-radius: $border-radius;
    margin-bottom: 10px;
  }
  @include media('>old') {

    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border: 0;

    }
  }


  &__info {
    width: 435px;
    flex: none;
    padding-right: 30px;

    @include media('<old') {
      padding-right: 0;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid #000;
      width: 100%;
    }
  }

  &__title {
    @include font-style($font_avenir_black, 36px, $white, 50px);
    margin: 0;
    margin-bottom: 5px;
    word-break: break-all;

    @include media('<laptop-small') {
      font-size: 26px;
      line-height: 1.4;
    }
    @include media('<old') {
      font-size: 21px;
    }
  }

  &__link {
    @include font-style($font_avenir_black, 21px, $white, 1.2);
    display: inline-block;
    color: $prime_color;
    text-decoration: none;
    transition: color .3s ease, transform .3s ease;

    @include media('<laptop-small') {
      font-size: 18px;
    }
    @include media('<old') {
      font-size: 14px;
    }
    &:hover {
      color: $prime_color_hover;
      transform: scale(1.05);
    }
  }

  &__content {
    flex: 1;
  }

  &__text {
    color: #D1D5DB;
    line-height: 1.4;

    @include media('<old') {
      color: rgba(255, 255, 255, 0.3);
    }
    a {
      color: $prime_color;
      transition: color .3s ease;
      text-decoration: underline;
      &:hover {
        color: $prime_color_hover;
        text-decoration: none;
      }
    }
  }
}