.contact-us-form {
  width: 570px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 45px;

  @include media('<568px') {
    padding-bottom: 0;
  }


  .ant-form-item {
    padding-bottom: 10px;

    .ant-input {
      @include font-style($font_avenir_regular, 14px, $white, 23px);
      border-radius: $border-radius;
      background: transparent;
      border: 1px solid rgba(130, 149, 166, 0.2);
      padding: 10px 25px;
      min-height: initial;
      box-shadow: none;
      transition: color .3s ease, border-color .3s ease;

      @include placeholder-font-family($font_avenir_regular);
      @include placeholder-color($gray);

      &:hover {
        box-shadow: none;
      }
    }

    &.ant-form-item-has-error {
      .ant-input {
        background: transparent;
        border-color: $red;
        @include placeholder-color($red);

      }
    }
    &.ant-form-item-has-success {
      .ant-input {
        background: transparent;
        border-color: $prime_color;
      }
    }

    .ant-form-item-explain {
      @include font-style($font_avenir_medium, 11px, $red, 1);
      padding-left: 25px;
      margin-top: 10px;
      min-height: initial;
    }
  }

  &__recaptcha {
    @include flex($justify-content: center);
    text-align: center;
  }
  &__recaptcha-wrap {
    text-align: center;

    &.ant-form-item {
      .ant-form-item-explain {
        padding-left: 0;
      }
    }

  }

  &__btn {
    width: 145px;
    display: block;
    margin: 0 auto;

    @include media('<568px') {
      width: 100%;
    }
  }
}